import Home from './pages/Home';
// import Dashboard from './pages/Dashboard';
import './App.css';
// import { Routes, Route } from "react-router-dom";

function App() {
  return (
    // <Routes>
    //   <Route exact path="/" element={<Home />} />
    //   <Route exact path="/dashboard" element={<Dashboard />} />
    // </Routes>
    <Home />
  );
}

export default App;
